<template>
  <SlModal
    :id="id"
    :title="modalTitle"
    :loading="isUploading"
    :persistent="isUploading"
    @on-enter="$emit('enter', handleUpload)"
    @hidden="onModalHide"
  >
    <div class="modal-content">
      <ValidationObserver ref="reUploadObserver">
        <SlValidate
          v-slot="{ invalid, validate }"
          vid="file"
          :rules="uploadConfigByFormat[fileFormat].validate"
        >
          <SlDropzone
            ref="dropzone"
            :accepted="uploadConfigByFormat[fileFormat].accepted"
            :validator="validate"
            :is-invalid="invalid"
            @files-updated="handleFilesUpdated"
          >
            <template #accepted>
              {{ $t(uploadConfigByFormat[fileFormat].label) }}
            </template>
          </SlDropzone>
        </SlValidate>
      </ValidationObserver>
      <transition name="fade">
        <SlAlert
          v-if="errorData"
          type="error"
          :text="errorData.message"
        >
          <template #link>
            <SlLink
              v-if="errorData.link"
              @click="handleOpenChat"
            >
              {{ $t('Web.Modals.Error.ContactSupport') }}
            </SlLink>
          </template>
        </SlAlert>
      </transition>
    </div>

    <template #footer>
      <SlModalFooter between>
        <div class="sl-modal__footer-group">
          <template v-if="$scopedSlots.footer">
            <slot
              name="footer"
              v-bind="{
                handleCancel,
                handleUpload,
                newFile
              }"
            />
          </template>
          <template v-else>
            <SlButton
              variant="secondary"
              color="grey"
              @click="handleCancel"
            >
              {{ $t('Common.Cancel') }}
            </SlButton>
            <SlButton
              @click="handleUpload"
            >
              {{ $t('Web.Modals.BtnSave') }}
            </SlButton>
          </template>
        </div>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { modal } from '@/mixins/modal';
import { uploadConfigByFormat } from '@/config/utils/fileUpload.config';
import modalsId from '@/config/shared/modalsId.config';
import { slErrorCodes } from '@/config/api/slErrorCodes.config';

export default {
  name: 'UploadModal',
  mixins: [modal],
  props: {
    id: {
      type: String,
      required: true
    },
    fileFormat: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: ''
    },
    uploadCallback: {
      type: Function,
      default: null
    }
  },
  data() {
    return {
      isUploading: false,
      newFile: null,
      errorData: null,
      uploadConfigByFormat
    };
  },
  computed: {
    modalTitle() {
      return this.title || this.$t('Web.File.UploadFile');
    }
  },
  methods: {
    onModalHide() {
      this.isUploading = false;
      this.newFile = false;
      this.errorData = null;
    },
    handleCancel() {
      this.hideModal(this.id);
    },
    async handleUpload() {
      const valid = await this.$refs.reUploadObserver.validate();
      const dropzoneValid = this.$refs.dropzone.validate();

      if (!valid || !dropzoneValid) {
        return;
      }

      const uploadPayload = await this.uploadFile();

      this.hide();

      return uploadPayload;
    },
    async uploadFile() {
      if (!this.uploadCallback) {
        return this.$emit('upload-file', this.newFile);
      }

      this.errorData = null;

      try {
        this.isUploading = true;

        const response = await this.uploadCallback(this.newFile);

        return response;
      } catch (error) {
        const isInaccessibleTenant = error?.code === slErrorCodes.INACCESSIBLE_PROJECT_TENANT;

        this.errorData = {
          message: isInaccessibleTenant
            ? this.$t('Web.Project.InaccessibleFileAlert') + '\n' + this.$t('Web.FileUpload.UploadAnotherFileOr', { 1: '' })
            : error?.message || this.$t('Web.FileUpload.TryAgain'),
          link: isInaccessibleTenant
        };

        // super-kostyl to avoid sl-error-boundary flow
        throw '';
      } finally {
        this.isUploading = false;
      }
    },
    hide() {
      if (this.isUploading) {
        return;
      }

      this.hideModal(this.id);
    },
    async handleFilesUpdated(files) {
      this.errorData = null;
      this.newFile = files[0];
    },
    handleOpenChat() {
      this.showModal(modalsId.REPORT_ISSUE);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/modals/connection/connectors/spreadsheet/spreadsheet-connector-modal.scss";

.modal-content {
  padding-bottom: 10px;
}
</style>
