import inventoryReportApi from '@/api/reports/inventoryReport';
import collections from '@/store/modules/report/inventoryReport/collections';
import plannedOrders from '@/store/modules/report/inventoryReport/plannedOrders';
import inTransition from '@/store/modules/report/inventoryReport/inTransition';
import constrainedPlan from '@/store/modules/report/inventoryReport/constrainedPlan';
import InventoryRequestAdapter from '@/adapters/request/InventoryRequest.adapter';
import ExplanationResponseAdapter from '@/adapters/response/ExplanationTable.adapter';
import { namespaceByRoute } from '@/config/report';
import { containerTypes } from '@/config/report/inventoryReport/collections.config';
import { slErrorCodes } from '@/config/api/slErrorCodes.config';
import { getRouteName } from '@/helpers/shared/router';
import { getSearchQuery } from '@/helpers/report/inventoryReport';
import { dealWith } from '@/api/helpers/errorRegistry';
import { ampli } from '@/plugins/product/ampli';

const types = {
  SET_DROPDOWN: 'SET_DROPDOWN',
  SET_EXPLANATION_TABLE: 'SET_EXPLANATION_TABLE'
};

const state = () => ({
  dropdowns: {
    dc: [],
    [containerTypes.GROUP]: [],
    [containerTypes.CONTAINER]: [],
    [containerTypes.MANUFACTURE_GROUP]: []
  },
  explanationTable: null
});

const mutations = {
  [types.SET_DROPDOWN](state, { key, value }) {
    state.dropdowns[key] = value;
  },
  [types.SET_EXPLANATION_TABLE](state, value) {
    state.explanationTable = value;
  }
};

const actions = {
  async updateTable({ rootGetters }, payload) {
    const namespace = namespaceByRoute[getRouteName()];
    const updateReq = payload.isAllRows ? inventoryReportApi.massOverride : inventoryReportApi.updateTableData;
    const reqPayload = InventoryRequestAdapter(payload);
    const body = payload.isAllRows
      ? {
        col: reqPayload.cols.col,
        override: reqPayload.tableData.col.row[0]
      }
      : reqPayload;

    try {
      this.dispatch(`${namespace}/setIsUpdating`, true);

      await updateReq({
        body,
        type: rootGetters[`${namespace}/reportType`](),
        id: rootGetters[`${namespace}/activeFilterId`],
        query: payload.searchQuery
      });
    } catch (e) {
      dealWith(
        [
          slErrorCodes.INVALID_NUMBER_LIMIT,
          slErrorCodes.INVALID_NUMBER,
          slErrorCodes.INVALID_INPUT
        ].reduce((acc, code) => {
          acc[code] = {
            text: (e) => e.message
          };

          return acc;
        }, {})
      )(e);

      this.dispatch(`${namespace}/fetchPage`);
      this.dispatch('user/logout', { e, from: 'updateTable' });
    } finally {
      this.dispatch(`${namespace}/setIsUpdating`, false);

      ampli.inventoryTableOverridden({
        inventoryTableColumn: body.columnKey
      });
    }
  },
  async fetchDCs({ commit }, payload) {
    try {
      const response = await inventoryReportApi.getDcs(payload);

      if (!response?.data) {
        return;
      }

      commit(types.SET_DROPDOWN, {
        key: 'dc',
        value: response.data.list
      });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchDCs' });
    }

  },
  async clearOverrides({ rootState, rootGetters }, payload) {
    const namespace = namespaceByRoute[getRouteName()];

    try {
      this.dispatch(`${namespace}/setIsUpdating`, true);

      const isAllRows = !payload?.rows?.length;
      const clearOverridesReq = isAllRows ? inventoryReportApi.massClearOverrides : inventoryReportApi.clearOverrides;
      const reqPayload = {
        ...payload,
        type: rootGetters[`${namespace}/reportType`](),
        id: rootGetters[`${namespace}/activeFilterId`]
      };

      if (isAllRows) {
        reqPayload.query = getSearchQuery(rootState[namespace].isLink);
      }

      await clearOverridesReq(reqPayload);

      this.dispatch(`${namespace}/fetchPage`);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'clearOverrides' });
    } finally {
      this.dispatch(`${namespace}/setIsUpdating`, false);
    }
  },
  exportTableToXlsx({ rootGetters }) {
    const namespace = namespaceByRoute[getRouteName()];

    return inventoryReportApi.exportTableToXlsx({
      id: rootGetters[`${namespace}/tableId`],
      type: rootGetters[`${namespace}/reportType`]()
    });
  },
  exportParamsToXlsx({ rootGetters }) {
    const namespace = namespaceByRoute[getRouteName()];

    return inventoryReportApi.exportParamsToXlsx({
      id: rootGetters[`${namespace}/tableId`],
      type: rootGetters[`${namespace}/reportType`]()
    });
  },
  importParams({ rootGetters }, body) {
    const namespace = namespaceByRoute[getRouteName()];

    return inventoryReportApi.importParams({
      id: rootGetters[`${namespace}/tableId`],
      type: rootGetters[`${namespace}/reportType`]()
    }, body);
  },
  setDropdown({ commit }, payload) {
    commit(types.SET_DROPDOWN, payload);
  },
  async fetchExplanation({ commit, rootGetters }, { item, location, manufactureDetails = false, backordersDetails = false }) {
    try {
      const namespace = namespaceByRoute[getRouteName()];

      const response = await inventoryReportApi.getExplanation({
        type: rootGetters[`${namespace}/reportType`](),
        item,
        location,
        manufactureDetails,
        backordersDetails
      });

      if (!response.data) {
        return;
      }

      commit(types.SET_EXPLANATION_TABLE, ExplanationResponseAdapter(response.data.explainData));
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchExplanation' });
    }
  },
  async fetchOrderingDays({ rootGetters }, { item, location }) {
    try {
      const namespace = namespaceByRoute[getRouteName()];

      const response = await inventoryReportApi.getOrderingDays({
        type: rootGetters[`${namespace}/reportType`](),
        item,
        location
      });

      return response?.data?.days || [];
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'getOrderingDays' });
    }
  },
  async resetNextOrderDate(_, body) {
    const namespace = namespaceByRoute[getRouteName()];

    try {
      this.dispatch(`${namespace}/setIsUpdating`, true);

      await inventoryReportApi.resetNextOrderDate(body);
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'resetNextOrderDate' });
    } finally {
      this.dispatch(`${namespace}/setIsUpdating`, false);
    }
  },
  async copyQtyToOverride({ rootState, rootGetters }, entities) {
    const namespace = namespaceByRoute[getRouteName()];

    try {
      this.dispatch(`${namespace}/setIsUpdating`, true);

      await inventoryReportApi.copyQtyToOverride(
        {
          type: rootGetters[`${namespace}/reportType`](),
          id: rootState[namespace].active_filter_id,
          query: getSearchQuery(rootState[namespace].isLink)
        },
        { entities }
      );
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'copyQtyToOverride' });
    } finally {
      this.dispatch(`${namespace}/setIsUpdating`, false);
    }
  }
};

export default {
  namespaced: true,
  modules: {
    collections,
    plannedOrders,
    inTransition,
    constrainedPlan
  },
  state,
  mutations,
  actions
};
