export default {
  // eslint-disable-next-line
  password: /^(?=.*[a-z])(?=.*(\d|[!@#$%^&*<>~_+\-=?.,"':;\[\]}{()|/\\]))[\w\d!@#$%^&*<>~_+\-=?.,"':;\[\]}{()|/\\]{8,64}$/,
  spaces: /\s+/g,
  spacesOrNewlines: /\s|\n/g,
  simpleInput: /^(?!.*(?:[,. ]){2,})-?[\d,. ]*$/,
  intInput: /^[0-9]*$/,
  negativeIntInput: /^$|^-[0-9]*$|^[0-9]*$/,
  intSubmit: /^\s*$|^-*[0-9]+$/,
  floatInput: /^$|^\d*(\.?\d*)?$/,
  floatSubmit: /^\s*$|^-*(\d+(\.?\d+)*)?$/,
  negativeFloatInput: /^$|^[+-]?\d*(\.?\d*)?$/,
  forecastOverridesInput: /^\s?$|^(\d+)$|^(\d*>?)$|^([-+*]?)$|^([-+*]?\d*(\.?\d*)+>?)$|^(\*+\d*(\.?\d*)?[-+]?\d*(\.?\d*)?)$|^(\*?\d*(\.?\d*)?[-+]?\d+>?)$|^\d*(\.?\d*)?$/,
  forecastOverridesSubmit: /^(\s*(\*\s*((\d['\s]?)*([.,]?\d+|(\d['\s]?)+([.,]?\d*))([eE][+-]?\d*)?)?\d\s*)?\s*([+-]\s*(\d['\s]?)*\d\s*)?>?\s*|\s*([+-]?\s*(\d['\s]?)*\d*(\.?\d*)?\s*)?>?)$/,
  encodeSpace: /%20|%2520/g,
  fileName: /[/,?,:,&,\\,*,",<,>,|,#,%,.]/g,
  url: new RegExp(
    '^(https?:\\/\\/)?'  // protocol
    + '((([a-z\\d]([a-z\\d-_]*[a-z\\d])*)\\.)+[a-z]{2,}|' // default domain name
    + '(([a-z\\d]([a-z\\d-_]*[a-z\\d])*)\\.)?[a-z]{2,}|' // localhost
    + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
    + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
    + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
    + '(\\#[-a-z\\d_]*)?$', // fragment locator
    'i'
  ),
  nonDigit: /[^0-9]/g,
  parseCsv: /(?:^|\s*,\s*)(?:"([^"]*)"|([^",]*))(?:\s*|,|$)/g,
  shopifyShopName: /^[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]$/
};
