import { mapState, mapActions, mapGetters } from 'vuex';
import { modal } from '@/mixins/modal';
import { fileSaver } from '@/mixins/webAPI';
import { access } from '@/mixins/access';
import { keyCodes } from '@/config/utils/statusCodes.config';
import { bomTypes } from '@/config/item/bom.config';
import { routeNames } from '@/config/router/router.config';
import modalsId from '@/config/shared/modalsId.config';
import { contextMenuFgs } from '@/config/shared/fgs.config';
import { fgsActionTypes } from '@/config/shared/contextMenu.config';
import { updateData, logLevel } from '@/config/project/updateData.config';
import { namespaceByRoute } from '@/config/report';
import { DEFAULT_FILTER_ID } from '@/config/filter';
import { getCtrl, getDel } from '@/helpers/shared/listeners';
import { projectRedirect } from '@/helpers/router';

export const shortcut = {
  mixins: [modal, fileSaver, access],
  data() {
    return {
      reportRouteNames: [
        routeNames.INVENTORY,
        routeNames.INTERSTORE,
        routeNames.REPORTS
      ],
      restrictedRouteNames: [
        routeNames.PROJECT_LIST,
        routeNames.ARCHIVE,
        routeNames.EXAMPLES
      ]
    };
  },
  computed: {
    ...mapState({
      projectId: (state) => state.manageProjects.projectId,
      activeNode: (state) => state.demand.tree.active_node
    }),
    ...mapGetters({
      availableProjectTabs: 'access/availableProjectTabs',
      hasBom: 'project/hasBom',
      isTimeMachineActive: 'manageProjects/isTimeMachineActive',
      activeNodeId: 'demand/tree/activeNodeId'
    }),
    tabByKeycode() {
      if (!this.availableProjectTabs?.length) {
        return null;
      }

      return this.availableProjectTabs.reduce((acc, route, routeIndex) => {
        acc[keyCodes[routeIndex + 1]] = route;

        return acc;
      }, {});
    },
    currentHandler() {
      if (this.$el.hasAttribute('data-shortcut-report')) {
        return this.handleReportShortcuts;
      }

      if (this.$el.hasAttribute('data-shortcut-tree')) {
        return this.handleTreeShortcuts;
      }

      if (this.$el.hasAttribute('data-shortcut-table')) {
        return this.handleTableShortcuts;
      }

      return this.handleGlobalShortcuts;
    }
  },
  mounted() {
    document.addEventListener('keydown', this.currentHandler);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.currentHandler);
  },
  methods: {
    ...mapActions({
      saveProject: 'project/saveProject',
      downloadTruncatedProject: 'project/downloadTruncatedProject',
      toggleApprove: 'demand/tree/approve',
      toggleChecked: 'demand/tree/toggleCheckedItemState',
      toggleAttention: 'demand/tree/toggleAttentionItemState',
      setBomEntry: 'bom/setSelectedEntry'
    }),
    isInput(e) {
      return ['input', 'textarea'].includes(e?.target?.tagName?.toLowerCase());
    },
    isTextSelected() {
      const selection = window.getSelection();

      return selection && selection.type === 'Range';
    },
    handleGlobalShortcuts(e) {
      if (this.restrictedRouteNames.includes(this.$sl_routeName) || this.isInput(e)) {
        return;
      }

      // save project (ctrl + s)
      if (getCtrl(e) && e.keyCode === keyCodes.s) {
        e.preventDefault();

        return this.saveProject();
      }

      // switch app tabs (alt + nums(1-9))
      if (e.altKey && e.keyCode >= keyCodes['1'] && e.keyCode <= keyCodes['9']) {
        e.preventDefault();

        const tabPath = this.tabByKeycode[e.keyCode];

        if (tabPath && !this.$route.path.includes(tabPath)) {
          return this.$router.push({
            name: this.tabByKeycode[e.keyCode]
          });
        }
      }

      // open entire BOM (ctrl + b)
      if (getCtrl(e) && e.keyCode === keyCodes.b && this.hasBom) {
        e.preventDefault();

        this.setBomEntry({
          route: routeNames.INVENTORY
        });

        return this.$router.push({
          name: routeNames.BILL_OF_MATERIALS,
          params: {
            type: bomTypes.ENTIRE
          }
        });
      }

      // freeze simulation modal (ctrl + shift + d)
      if (getCtrl(e) && e.shiftKey && e.keyCode === keyCodes.d) {
        e.preventDefault();

        return this.showModal(modalsId.FREEZE_SIMULATION);
      }

      // re-import data (ctrl + d)
      if (getCtrl(e) && e.keyCode === keyCodes.d) {
        e.preventDefault();

        return this.handleReImport(updateData.QUICK, logLevel.OFF);
      }

      // re-import data with logs (alt + shift + q)
      if (
        e.altKey && e.shiftKey && e.keyCode === keyCodes.q
        && this.$sl_hasAccess(this.$sl_features.updatePrj)
        && !this.isTimeMachineActive && this.$sl_hasProjectAccess && !this.$sl_isEditingRoute
      ) {
        return this.showModal(modalsId.UPDATE_WITH_LOGS);
      }

      // open logs page (ctrl + shift + q)
      if (getCtrl(e) && e.shiftKey && e.keyCode === keyCodes.q) {
        return projectRedirect({
          name: routeNames.LOGS_PAGE
        });
      }
    },
    // to avoid duplicates handlers & computed used from TreeContextMenu.vue
    handleTreeShortcuts(e) {
      if (this.$sl_routeName !== routeNames.DEMAND || this.isInput(e)) {
        return;
      }

      // copy item code (ctrl + c)
      if (getCtrl(e) && e.keyCode === keyCodes.c) {
        if (this.isTextSelected()) {
          return;
        }

        e.preventDefault();

        return this.copyItem();
      }

      // open BOM (ctrl + m)
      if (getCtrl(e) && e.keyCode === keyCodes.m) {
        e.preventDefault();

        if (!this.isBomDisabled) {
          return this.handleOpenBoms();
        }
      }

      // toggle approve (alt + a)
      if (e.altKey && e.keyCode === keyCodes.a) {
        e.preventDefault();

        return this.toggleApprove();
      }

      // toggle attention (alt + n)
      if (e.altKey && e.keyCode === keyCodes.n) {
        e.preventDefault();

        return this.toggleAttention();
      }

      // toggle checked (alt + c)
      if (e.altKey && e.keyCode === keyCodes.c) {
        e.preventDefault();

        return this.toggleChecked();
      }

      // substitute (ctrl + u) if row selected
      if (getCtrl(e) && e.keyCode === keyCodes.u) {
        e.preventDefault();

        return this.handleSubstitute();
      }

      // remove form import (alt + delete) if row selected
      if (e.altKey && getDel(e)) {
        e.preventDefault();

        if (this.getActionFgs(contextMenuFgs.REMOVE_FROM_IMPORT, fgsActionTypes.ENABLED) && this.$sl_hasAccess(this.$sl_features.remSubsRules)) {
          return this.handleRemoveFromImport();
        }
      }

      // download truncated project (ctrl + alt + shift + l)
      if (getCtrl(e) && e.altKey && e.shiftKey && e.keyCode === keyCodes.l) {
        e.preventDefault();

        return this.saveFile(this.downloadTruncatedProject.bind(this, { nodeId: this.activeNodeId }));
      }
    },
    // to avoid duplicate report shortcuts handlers & computed used from InventoryTable.vue
    handleReportShortcuts(e) {
      if (!this.reportRouteNames.includes(this.$sl_routeName) || this.isInput(e)) {
        return;
      }

      // copy item code (ctrl + c)
      if (getCtrl(e) && e.keyCode === keyCodes.c) {
        if (this.isTextSelected()) {
          return;
        }

        e.preventDefault();

        return this.copyItemCode();
      }

      // select all rows (ctrl + a)
      if (getCtrl(e) && e.keyCode === keyCodes.a) {
        e.preventDefault();

        return this.handleSelectAllRows();
      }

      // open BOM (ctrl + m) if row selected
      if (getCtrl(e) && e.keyCode === keyCodes.m) {
        e.preventDefault();

        if (!this.isBomDisabled && this.lastSelectedItem !== null) {
          return this.openBomLink(this.lastSelectedItem);
        }
      }

      // substitute (ctrl + u) if row selected
      if (getCtrl(e) && e.keyCode === keyCodes.u) {
        e.preventDefault();

        if (this.lastSelectedItem !== null) {
          return this.handleSubstitute();
        }
      }

      // remove form import (alt + delete) if row selected
      if (e.altKey && getDel(e)) {
        e.preventDefault();

        if (this.lastSelectedItem !== null && this.$sl_hasAccess(this.$sl_features.remSubsRules)) {
          return this.handleRemoveFromImport();
        }
      }

      // next shortcuts blocked for reports route
      if (this.$sl_routeName === routeNames.REPORTS) {
        return;
      }

      // open explain calculation (ctrl + i) if row selected
      if (getCtrl(e) && !e.shiftKey && e.keyCode === keyCodes.i) {
        e.preventDefault();

        if (this.lastSelectedItem !== null) {
          return this.openExplainCalculation();
        }
      }

      // download truncated project (ctrl + alt + shift + l)
      if (getCtrl(e) && e.altKey && e.shiftKey && e.keyCode === keyCodes.l) {
        e.preventDefault();

        if (this.nested) {
          return;
        }

        const namespace = namespaceByRoute[this.$sl_routeName];

        const filterId = this.$store.getters[`${namespace}/activeFilterId`];

        if (filterId !== DEFAULT_FILTER_ID) {
          return this.saveFile(this.downloadTruncatedProject.bind(this, {
            type: this.$store.getters[`${namespace}/reportType`](),
            filterId
          }));
        }
      }
    },
    handleTableShortcuts(e) {
      if (e.target.tagName !== 'BODY') {
        return;
      }

      if (getCtrl(e) && e.keyCode === keyCodes.a) {
        e.preventDefault();

        return this.handleSelectRowsAll(!this.headerCheckboxValue);
      }
    }
  }
};
