import { toArray } from '@/helpers/utils/toArray';

export default {
  connection(data) {
    const {
      _login: login,
      _password: password
    } = data.settings.connection.credentials;
    const {
      _domain: domain,
      _port: port
    } = data.settings.connection.endpoint;
    const {
      _nMonthsToDropToReceive: nMonthsToDropToReceive,
      _loadCustomFields: loadCustomFields,
      _combineLocs: combineLocs
    } = data.settings.info;
    const { _name: fileName } = data.settings.certFile || {};
    const { _val: fileId } = data.settings.fileId || {};
    const { database } = data.settings.spireDatabases;

    const databases = toArray(database).map(({ location, _name, ...db }) => ({
      ...db,
      name: _name,
      locations: toArray(location).map(location => ({
        name: location,
        checked: true
      }))
    }));

    return {
      required: {
        login,
        password,
        port: +port,
        domain,
        databases
      },
      optional: {
        nMonthsToDropToReceive,
        loadCustomFields,
        combineLocs,
        fileName,
        fileId
      }
    };
  },
  databases(data, selectedDatabases = []) {
    const selectedDatabasesByName = selectedDatabases.reduce((acc, db) => {
      acc[db.name] = db;

      return acc;
    }, {});

    const allDatabases = data.map(db => ({
      ...db,
      locations: db.locations.map(location => ({
        name: location,
        checked: false
      }))
    }));

    const allDatabasesByName = allDatabases.reduce((acc, db) => {
      acc[db.name] = db;

      return acc;
    }, {});

    for (const dbName in allDatabasesByName) {
      if (!selectedDatabasesByName[dbName]) {
        selectedDatabasesByName[dbName] = allDatabasesByName[dbName];
      } else {
        selectedDatabasesByName[dbName].locations = allDatabasesByName[dbName].locations.map(location => {
          const existingLocation = selectedDatabasesByName[dbName].locations.find(loc => loc.name === location.name);

          return {
            ...location,
            checked: Boolean(existingLocation?.checked)
          };
        });
      }
    }

    return Object.values(selectedDatabasesByName);
  }
};
