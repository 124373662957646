import Vue from 'vue';
import seasonPatternApi from '@/api/seasonPattern';
import SeasonPatternRequestAdapter from '@/adapters/request/SeasonPatternRequest.adapter';
import GenericTableAdapter from '@/adapters/response/GenericTable.adapter';
import { getTableConfigParams, getTableConfigStructure } from '@/helpers/shared/tableConfig';

const types = {
  SET_SEASON_PATTERNS_MODEL: 'SET_SEASON_PATTERNS_MODEL',
  SET_SEASON_PATTERNS: 'SET_SEASON_PATTERNS',
  SAVE_PATTERN: 'SAVE_PATTERN',
  DELETE_PATTERN: 'DELETE_PATTERN',
  SET_IS_EDITED: 'SET_IS_EDITED',
  UPDATE_TABLE_CONFIG: 'UPDATE_TABLE_CONFIG'
};

const state = () => ({
  seasonal_patterns_model: [],
  seasonal_patterns: [],
  tableConfig: getTableConfigStructure(),
  is_edited: false
});

const getters = {
  patternModelNames: (state) => state.seasonal_patterns_model.map(item => item.name).slice(2),
  patternNames: (state) => state.seasonal_patterns?.rows?.map(item => item.name.val)
};

const mutations = {
  [types.SET_SEASON_PATTERNS_MODEL](state, value) {
    state.seasonal_patterns_model = value;
  },
  [types.SET_SEASON_PATTERNS](state, value) {
    state.seasonal_patterns = value;
  },
  [types.SAVE_PATTERN](state, { index, value }) {
    if (index !== -1) {
      Vue.set(state.seasonal_patterns, index, value);
    } else {
      state.seasonal_patterns.push(value);
    }
  },
  [types.DELETE_PATTERN](state, index) {
    Vue.set(state.seasonal_patterns, index, {
      ...state.seasonal_patterns[index],
      type: 'deleted'
    });
  },
  [types.SET_IS_EDITED](state, value) {
    state.is_edited = value;
  },
  [types.UPDATE_TABLE_CONFIG](state, { key, value }) {
    Vue.set(state.tableConfig, key, value);
  }
};

const actions = {
  async fetchSeasonPatternsModel({ commit }) {
    try {
      const response = await seasonPatternApi.getSeasonPatternsModel();

      if (response?.data) {
        commit(types.SET_SEASON_PATTERNS_MODEL, response.data.patterns);
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchSeasonPatternsModel' });
    }
  },
  async fetchSeasonPatterns({ state, commit }) {
    try {
      const response = await seasonPatternApi.getSeasonPatterns({
        ...getTableConfigParams(state.tableConfig)
      });

      if (response?.data) {
        commit(types.SET_SEASON_PATTERNS, GenericTableAdapter(response.data));
      }
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'fetchSeasonPatterns' });
    }
  },
  async getCurrentPattern(_, id) {
    try {
      const response = await seasonPatternApi.getSeasonPatterns({ id });
      const data = response?.data;

      if (!data) {
        return;
      }

      return {
        ...data,
        ...GenericTableAdapter(data.table)
      };
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'getCurrentPattern' });
    }
  },
  async saveSeasonPattern(_, { data, id }) {
    try {
      await seasonPatternApi.updateSeasonalPatterns(SeasonPatternRequestAdapter(data), { id });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'saveSeasonPattern' });

      throw e;
    }
  },
  async createPattern(_, { data, fromChannel }) {
    try {
      await seasonPatternApi.createPattern(SeasonPatternRequestAdapter(data), { fromChannel });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'createPattern' });

      throw e;
    }
  },
  async saveAsPattern(_, { data, fromChannel }){
    try {
      await seasonPatternApi.createPattern(data, { fromChannel });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'saveAsPattern' });
    }
  },
  async deletePattern(_, id) {
    try {
      await seasonPatternApi.deletePattern( { id });
    } catch (e) {
      this.dispatch('user/logout', { e, from: 'deletePattern' });
    }
  },
  setIsEdited({ commit }, value) {
    commit(types.SET_IS_EDITED, value);
  },
  updateTableConfig({ commit }, payload) {
    commit(types.UPDATE_TABLE_CONFIG, payload);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
